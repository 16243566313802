import {
  CacheHandler,
  CartActions,
  CustomerCart,
  Gift,
  GuestCart,
  ICreateCustomerResponse,
  IGetRecommendationsResponse,
  IMagentoItem,
  IMagentoStore,
  IProductAttributes,
  IRecommendation,
  MagentoItem,
  MagentoServiceHandler,
  ProductActions,
  RestServiceHandler,
  ThreadHandler,
  Wine,
  ICustomAttribute,
  WishListActions,
  MyDiscoveryActions,
  EventHandler,
  IInCartItemTotal,
  InCartItem,
  ICustomer,
  ISearchFilterItem,
  ISearchFilter,
  IElasticSearchBucket,
  ICombinedCartItem,
  Timer,
  AnalyticHandler,
  convertBasedOnSGTime,
  SiteLang,
  getPlatform,
} from '..';
import {
  PASSWORD_MIN_CHARS,
  PASSWORD_MIN_CLASSES,
  INTERNAL_USERS,
  getSearchFilterRanking,
  PUBLIC_HOLIDAYS,
  getImageS3Url,
  WDExpressWording,
} from '../Config';
import {CustomerActions, getBuildType} from '..';
import {
  toTimeZone,
  getSortOptionList,
  parseDate,
  formatNumber,
  formatToMagentoDate,
  getDayOfWeek,
  getUrlDomain,
  deepClone,
  decodeChar,
  getSGTime,
  isSameDay,
} from './Helper';
import {ProductAttributeMap} from '../Model/ProductAttributeMap';
import {NotificationActions} from '../MobxStore/NotificationActions';
import {Logger} from '../Handler/Logger';
import {LangActions} from '../MobxStore/LangActions';
import moment from 'moment';
// tslint:-next-line:class-name

export class WD {
  static store: IMagentoStore;

  static init(
    magentoStore: IMagentoStore,
    cacheHandler: CacheHandler,
    threadHandler: ThreadHandler,
    {
      updateProducts = true,
      updateCart = true,
      useCache = true,
      autoRefresh = true,
      customerToken = '',
      defaultLocale = null,
    } = {},
  ) {
    magentoStore.cacheHandler = cacheHandler;
    magentoStore.threadHandler = threadHandler;
    this.store = magentoStore;
    LangActions.init(defaultLocale as SiteLang);
    magentoStore.service = new MagentoServiceHandler(new RestServiceHandler(customerToken, magentoStore, {useCache}));
    if (updateProducts) ProductActions.refresh();
    if (updateCart) CartActions.refresh();

    if (autoRefresh) {
      setTimeout(() => {
        ProductActions.refresh(false);
      }, 20000);
    }
  }
  static toMagentoItem(magentoItem: IMagentoItem) {
    const i = new MagentoItem(magentoItem);
    return i.isWine() ? new Wine(i) : new Gift(i);
  }
  static toMagentoItems(magentoItems: IMagentoItem[]) {
    if (magentoItems) {
      return magentoItems.map((m) => this.toMagentoItem(m));
    } else {
      return [];
    }
  }
  static isInList(item: IMagentoItem, list: IMagentoItem[]) {
    const i = list.find((ii) => ii.sku === item.sku);
    return i ? true : false;
  }
  static isNotInList(item: IMagentoItem, list: IMagentoItem[]) {
    return !this.isInList(item, list);
  }
  static addToList(newItems: IMagentoItem[], list: MagentoItem[]): (Wine | Gift | MagentoItem)[] {
    if (!list) list = [];
    const originalList = list.map((i) => i.data);
    const withoutNewItems = originalList.filter((i) => WD.isNotInList(i, newItems));
    const final = WD.toMagentoItems(withoutNewItems.concat(newItems));
    return final;
  }
  static addToItemMap(list: IMagentoItem[], map: {[sku: string]: MagentoItem}) {
    const newMap = {...map};
    for (const item of list) {
      newMap[item.sku] = WD.toMagentoItem(item);
    }
    return newMap;
  }
  static mergeItemMap(from: {[sku: string]: {data: IMagentoItem}}, to: {[sku: string]: MagentoItem}) {
    const newMap = {...to};
    for (const sku in from) {
      const item = from[sku];
      newMap[sku] = WD.toMagentoItem(item.data);
    }
    return newMap;
  }
  static isValidItem(item: IMagentoItem) {
    return WD.isValidSku(item.sku);
  }
  static isValidSku(sku: string) {
    if (sku) {
      return sku.indexOf('M') >= 0 || sku.indexOf('Gift') >= 0;
    }
    return false;
  }
  static isItemLoaded(sku: string) {
    return !!WD.store.itemMap[sku];
  }
  static async getProductOption(inCartItem: InCartItem, title: string): Promise<string | undefined> {
    const ids = await WD.store.service.getProductOptionIds(inCartItem.sku);
    const obj = ids.find((id) => id.title === title);
    if (obj && inCartItem && inCartItem.product_option && inCartItem.product_option.extension_attributes) {
      const option = inCartItem.product_option.extension_attributes.custom_options.find(
        (o) => o.option_id + '' === obj.option_id + '',
      );
      if (option) {
        return option.option_value as any;
      }
    }
    return undefined;
  }
  static formatDate(
    dateString: string,
    {offset = -new Date().getTimezoneOffset(), preProcessDateString = true, includeTime = false} = {},
  ): string {
    let d: Date;
    if (preProcessDateString) {
      d = parseDate(dateString);
    } else {
      d = new Date(dateString);
    }
    const offsetMilliseconds = 1000 * 60 * 60 * offset;
    d = new Date(d.getTime() + offsetMilliseconds);
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    let str = `${d.getDate()} ${monthNames[d.getMonth()]} ${d.getFullYear()}`;

    if (includeTime) {
      str = `${str} ${this.formatTime(dateString, {preProcessDateString, offset})}`;
    }
    return str;
  }
  static formatTime(
    dateString: string,
    {offset = -new Date().getTimezoneOffset(), preProcessDateString = true} = {},
  ): string {
    let d: Date;
    if (preProcessDateString) {
      d = parseDate(dateString);
    } else {
      d = new Date(dateString);
    }
    d = new Date(d.getTime() + 1000 * 60 * 60 * offset);

    let hour = d.getHours();
    const AMPM = hour >= 12 ? 'pm' : 'am';
    if (hour > 12) {
      hour -= 12;
    }
    return `${formatNumber(hour, 2)}:${formatNumber(d.getMinutes(), 2)} ${AMPM}`;
  }
  static translateOrderStatus(status: string) {
    const statusMap = {
      processing: WD.store.appLocale.customer_web.setting.paid,
      fraud: 'Suspected Fraud',
      pending_payment: 'Pending Payment',
      payment_review: 'Payment Review',
      pending: WD.store.appLocale.customer_web.setting.pending,
      holded: 'On Hold',
      out_for_delivery: WD.store.appLocale.customer_web.setting.paid,
      complete: 'Complete',
      closed: 'Closed',
      canceled: 'Canceled',
      pending_paypal: 'Pending PayPal',
      paypal_reversed: 'PayPal Reversed',
      paypal_canceled_reversal: 'PayPal Canceled Reversal',
      delivered: WD.store.appLocale.customer_web.setting.delivered,
    };
    return statusMap[status];
  }

  static validateEmail(email) {
    // tslint:disable-next-line:max-line-length
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email.toLowerCase());
  }

  static validatePassword(password: string) {
    if (password.length < PASSWORD_MIN_CHARS) {
      return false;
    }
    let classCount = 0;
    if (/[A-Z]/g.test(password)) {
      classCount++;
    }

    if (/[a-z]/g.test(password)) {
      classCount++;
    }

    if (/[0-9]/g.test(password)) {
      classCount++;
    }
    if (/[^a-zA-Z0-9]/g.test(password)) {
      classCount++;
    }

    return classCount >= PASSWORD_MIN_CLASSES;
  }
  static async getGuestCartID() {
    return WD.store.cacheHandler.loadFromDiskSequential('guestCartID');
  }
  static getTokenCacheKey() {
    return 'token_' + getBuildType();
  }
  static getLoginToken() {
    return WD.store.cacheHandler.loadFromDiskSequential(this.getTokenCacheKey(), -1);
  }
  static async getTokenizedService(token?: string) {
    if (!token) {
      token = await this.getLoginToken();
    }
    return new MagentoServiceHandler(new RestServiceHandler(token, WD.store));
  }
  static getNonTokenizedService() {
    return new MagentoServiceHandler(new RestServiceHandler('', WD.store));
  }
  // TODO: Refactor out the part where it will refresh user's data if user just logged in,
  // 		 this function is doing too many different thing
  static async isUserLoggedIn() {
    let customer: ICustomer = null;
    try {
      const token = await this.getLoginToken();
      console.log('customer token', token);
      if (token) {
        const service = await this.getTokenizedService();
        customer = await service.getCustomerInfo();
        if (!customer.email) {
          throw 'Consumer is not authorized';
        }
        WD.store.isLoggedIn = !customer.message;
      } else {
        throw 'Consumer is not authorized';
      }
    } catch (ignored) {
      console.log(ignored);
      if ((ignored + '').indexOf('Consumer is not authorized') >= 0) {
        WD.store.isLoggedIn = false;
      } else {
        console.error(new Error(ignored));
      }
    }
    if (WD.store.isLoggedIn) {
      let refreshStuffs = false;
      if (!WD.store.customer) {
        refreshStuffs = true;
      }
      WD.store.customer = customer;
      WD.store.service = await WD.getTokenizedService();
      if (refreshStuffs) {
        console.log('customer', WD.store.customer);
        WishListActions.refresh();
        MyDiscoveryActions.refresh();
        ProductActions.refreshRequestedWines();
        CustomerActions.refreshOrders();
        CustomerActions.refreshSavedCreditCards();
        ProductActions.refreshPersonalRecommendation();
        EventHandler.trigger('onLogin', customer);
      }
      console.log('customer is login');
    } else {
      WD.store.service = WD.getNonTokenizedService();
      WD.store.customer = null;
      console.log('customer is NOT login');
    }
    return WD.store.isLoggedIn;
  }
  static getItem(sku: string, autoLoadItem = true): MagentoItem | Wine | Gift {
    let item = WD.store.itemMap[sku];
    if (!item) {
      const loadingItem = {
        id: -1,
        sku: sku ? sku : -1,
        name: '',
      };

      item = new MagentoItem(loadingItem as any);
      if (autoLoadItem) {
        setTimeout(() => {
          ProductActions.loadItem(sku);
        }, 0);
      }
    }
    return item;
  }
  static getItems(skus: string[] = []) {
    const dontHave = skus.filter(WD.isValidSku).filter((sku) => WD.isItemLoaded(sku) === false);
    if (dontHave.length > 0) {
      ProductActions.loadItemsSync(dontHave);
    }
    const items = skus.map((sku) => this.getItem(sku, false));
    return items;
  }

  static getSelectedDeliveryDate(): Date {
    try {
      const dateString = WD.store.cartInfo.extension_attributes.selected_delivery_timeslot.delivery_date;
      if (dateString) return new Date(dateString);
      else return null;
    } catch (e) {
      return null;
    }
  }
  static getSelectedDeliveryTime(): string {
    try {
      const dateString = WD.store.cartInfo.extension_attributes.selected_delivery_timeslot.delivery_time;
      return dateString;
    } catch (e) {
      return null;
    }
  }

  static setSelectedDeliveryDate(deliveryDate) {
    if (deliveryDate)
      WD.store.cartInfo.extension_attributes.selected_delivery_timeslot.delivery_date = deliveryDate.toString();
  }

  static setSelectedDeliveryTime(deliveryTime) {
    if (deliveryTime) WD.store.cartInfo.extension_attributes.selected_delivery_timeslot.delivery_time = deliveryTime;
  }

  static setSelectedExpressTimeslot(timeslot) {
    if (timeslot) WD.store.expressSelectedTimeslot = timeslot;
  }

  static getUserSelectedDeliveryDate() {
    try {
      const dateString = WD.store.selectedDeliveryDate;
      if (dateString) return new Date(dateString);
      else return null;
    } catch (e) {
      return null;
    }
  }
  static getUserSelectedDeliveryTime() {
    return WD.store.selectedDeliveryTime;
  }
  static setUserSelectedDeliveryDate(deliveryDate) {
    if (deliveryDate) {
      WD.store.selectedDeliveryDate = deliveryDate.toString();
      if (WD.store.selectedDeliveryDate) {
        const expressDate = WD.getExpressEarliestDate();
        if (!isSameDay(expressDate, moment(WD.store.selectedDeliveryDate).toDate())) {
          WD.store.expressSelectedTimeslot = '';
          WD.store.showExpressTimeslot = false;
        }
      }
    }
  }
  static setUserSelectedDeliveryTime(deliveryTime) {
    if (deliveryTime) {
      WD.store.selectedDeliveryTime = deliveryTime;
      if (WD.store.expressCartEligible === 'eligible' && WD.store.selectedDeliveryDate) {
        const expressDate = this.getExpressEarliestDate();
        if (isSameDay(expressDate, new Date(WD.store.selectedDeliveryDate))) {
          WD.store.expressSelectedTimeslot = deliveryTime;
        }
      }
    }
  }

  static getSelectedDate() {
    let selectedDate = null;
    const blockedDate = WD.store.blockedDeliveryDates;
    const defaultDeliveryDate = formatToMagentoDate(WD.getDefaultSelectedDeliveryDate());
    const defaultDeliveryDateExpress = formatToMagentoDate(WD.getDefaultSelectedDeliveryDateExpress());
    const blockedDeliveryDates = blockedDate || WD.store.blockedDeliveryDates;
    const userSelectedDeliveryDate = formatToMagentoDate(WD.getUserSelectedDeliveryDate());
    if (userSelectedDeliveryDate !== '') {
      if (userSelectedDeliveryDate > defaultDeliveryDate) {
        if (blockedDeliveryDates.length > 0 && blockedDeliveryDates.indexOf(userSelectedDeliveryDate) !== -1) {
          selectedDate = this.isBlockedDate(defaultDeliveryDate, blockedDeliveryDates);
        } else {
          selectedDate = this.isBlockedDate(userSelectedDeliveryDate, blockedDeliveryDates);
        }
      } else {
        selectedDate = this.isBlockedDate(defaultDeliveryDate, blockedDeliveryDates);
      }
    } else {
      selectedDate = this.isBlockedDate(defaultDeliveryDateExpress, blockedDeliveryDates);
    }
    return selectedDate;
  }

  static isBlockedDate = (currentDate: string, blockedDate: string[]) => {
    let blocked = true;
    let availableDate = convertBasedOnSGTime(currentDate);
    if (blockedDate.length > 0) {
      while (blocked) {
        const dateString = formatToMagentoDate(availableDate);
        if (blockedDate.findIndex((d) => d === dateString) !== -1) {
          availableDate = new Date(availableDate.getTime() + 24 * 60 * 60 * 1000);
        } else {
          blocked = false;
        }
      }
    }
    return availableDate;
  };

  static getEarliestDeliveryDate(): Date {
    try {
      let dateString = WD.store.cartInfo.extension_attributes.calculated_delivery_timeslot.delivery_date;
      // NEED TO DO THIS FOR IE
      if (getPlatform() !== 'android') {
        dateString = dateString.replace(/-/gm, '/');
      }
      const earliestDate = new Date(dateString);
      // block delivery date for sunday
      // if(earliestDate.getDay() === 0) {
      // 	earliestDate.setDate(earliestDate.getDate() + 1);
      // }
      return earliestDate;
    } catch (e) {
      return null;
    }
  }
  static getDefaultSelectedDeliveryDate(): Date {
    return WD.getEarliestDeliveryDate();
  }
  static getDefaultSelectedDeliveryDateExpress(): Date {
    const earliestDeliveryDate = WD.getEarliestDeliveryDate();
    const tomorrow = new Date(getSGTime().getTime() + 24 * 60 * 60 * 1000);
    const nextNextDay = new Date(getSGTime().getTime() + 48 * 60 * 60 * 1000);

    // If before cut off time, for express eligible default date should be tomorrow
    // If after cut off time, for express eligible default date should be next next day or earliest delivery date,
    // whichever later
    if (this.isExpressBeforeCutOffTime()) {
      return WD.store.expressCartEligible === 'eligible' ? tomorrow : earliestDeliveryDate;
    } else {
      if (WD.store.expressCartEligible === 'eligible') {
        return earliestDeliveryDate > nextNextDay ? earliestDeliveryDate : nextNextDay;
      } else {
        return earliestDeliveryDate;
      }
    }
  }
  static getEarliestDeliveryTime(): string {
    try {
      return WD.store.cartInfo.extension_attributes.calculated_delivery_timeslot.delivery_time;
    } catch (e) {
      return null;
    }
  }
  static resetExpressData(isLoggedIn?: boolean) {
    WD.store.expressSelectedTimeslot = '';
    WD.store.showExpressTimeslot = false;
    if (WD.store.selectedDeliveryDate) {
      // If user choose date > express date, no need to reset the selected date
      if (
        formatToMagentoDate(new Date(WD.store.selectedDeliveryDate)) ===
        formatToMagentoDate(new Date(WD.store.expressEarliestDeliveryDate.replace(/-/g, '/')))
      ) {
        WD.store.selectedDeliveryDate = null;
      }
    }
    CartActions.refresh(isLoggedIn, true);
  }
  static getCouponCode() {
    return WD.store.cartTotal.coupon_code;
  }
  static getCartOperator() {
    return WD.store.isLoggedIn ? new CustomerCart() : new GuestCart();
  }

  static getHomeProducts(): Wine[] {
    return WD.applyPlaceholder(WD.getItems(WD.store.homeProducts));
  }
  static getLatestProducts(): Wine[] {
    return WD.applyPlaceholder(WD.getItems(WD.store.latestProducts));
  }
  static getSaleProducts(): Wine[] {
    return WD.applyPlaceholder(WD.getItems(WD.store.saleProducts));
  }
  static getSpiritProducts(): Wine[] {
    return WD.applyPlaceholder(WD.getItems(WD.store.spiritProducts));
  }
  static getOrganicProducts(): Wine[] {
    return WD.applyPlaceholder(WD.getItems(WD.store.organicProducts));
  }
  static getStaffPicks(): Wine[] {
    return WD.applyPlaceholder(WD.getItems(WD.store.staffPicks));
  }
  static getNextDayDeliveries(): Wine[] {
    return WD.applyPlaceholder(WD.getItems(WD.store.nextDayDeliveries));
  }
  static getExpressProducts(): Wine[] {
    return WD.applyPlaceholder(WD.getItems(WD.store.expressProducts));
  }
  static isNextDayDeliveryEmpty() {
    return this.store.nextDayDeliveries.length === 0;
  }
  static isExpressProductsEmpty() {
    return this.store.expressProducts.length === 0;
  }
  static isExpressBeforeCutOffTime(): boolean {
    const cutOffTime = this.store.expressCutOffTime;
    if (cutOffTime) {
      const handledTime = moment(cutOffTime).toDate();
      return getSGTime().getTime() < handledTime.getTime();
    }
    return false;
  }
  static isExpressEarliestTomorrow(): boolean {
    const earliestTime = this.store.expressEarliestDeliveryDate;
    if (earliestTime) {
      const earliestHandledTime = moment(earliestTime).toDate();
      const tomorrow = moment(getSGTime().getTime() + 24 * 60 * 60 * 1000).toDate();
      return isSameDay(tomorrow, earliestHandledTime);
    }
    return false;
  }
  static expressSectionLabel(): string {
    if (this.isExpressBeforeCutOffTime()) {
      return this.isExpressEarliestTomorrow()
        ? this.store.appLocale.customer_web.home.available_tomorrow
        : this.store.appLocale.customer_web.home.available_today;
    } else {
      return this.store.appLocale.customer_web.home.available_tomorrow;
    }
  }
  static getExpressEarliestDate(): Date {
    const earliestTime = this.store.expressEarliestDeliveryDate;
    if (earliestTime) {
      const earliestHandledTime = moment(earliestTime).toDate();
      return earliestHandledTime;
    }
    return getSGTime();
  }
  static getExpressWording(type: string): string {
    return WD.store.appLocale.customer_web.wd_express[WDExpressWording[type]];
  }
  static isExpressBlocked(): boolean {
    const DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000;
    const now = toTimeZone(new Date(), 8);
    const tomorrow = new Date(now.getTime() + DAY_IN_MILLISECONDS);
    return (
      WD.store.blockedDeliveryDates.indexOf(
        this.isExpressBeforeCutOffTime() ? formatToMagentoDate(now) : formatToMagentoDate(tomorrow),
      ) !== -1
    );
  }
  static isNextDayDeliveryBlocked(): boolean {
    let blocked = false;
    const now = toTimeZone(new Date(), 8);
    const dayOfTheWeek = now.getDay();
    const hour = now.getHours();
    const DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000;
    const tomorrow = new Date(now.getTime() + DAY_IN_MILLISECONDS);
    const dayAfterTomorrow = new Date(tomorrow.getTime() + DAY_IN_MILLISECONDS);
    const tomorrowFormated = formatToMagentoDate(tomorrow);
    const afterTomorrowFormated = formatToMagentoDate(dayAfterTomorrow);
    if (dayOfTheWeek === 4 && hour >= 17 && WD.store.blockedDeliveryDates.indexOf(afterTomorrowFormated) !== -1) {
      blocked = true;
    } else if (dayOfTheWeek === 5 && hour < 17 && WD.store.blockedDeliveryDates.indexOf(tomorrowFormated) !== -1) {
      blocked = true;
    } else if (
      dayOfTheWeek === 5 &&
      hour >= 17 &&
      WD.store.blockedDeliveryDates.indexOf(afterTomorrowFormated) !== -1
    ) {
      blocked = true;
    } else if (dayOfTheWeek === 6 && hour < 17 && WD.store.blockedDeliveryDates.indexOf(tomorrowFormated) !== -1) {
      blocked = true;
    } else if (
      PUBLIC_HOLIDAYS.indexOf(afterTomorrowFormated) >= 0 &&
      hour >= 17 &&
      WD.store.blockedDeliveryDates.indexOf(afterTomorrowFormated) !== -1
    ) {
      blocked = true;
    } else if (
      PUBLIC_HOLIDAYS.indexOf(tomorrowFormated) >= 0 &&
      hour < 17 &&
      WD.store.blockedDeliveryDates.indexOf(tomorrowFormated) !== -1
    ) {
      blocked = true;
    }
    return blocked;
  }
  static applyPlaceholder(items: MagentoItem[]): Wine[] {
    if (!items || items.length === 0) {
      return WD.getLoadingItems(20) as any;
    }
    return items as any;
  }

  private static getLoadingItems(amount: number) {
    let index = -1;
    return new Array(amount)
      .fill(1)
      .map((_) => index-- + '')
      .map((sku) => {
        const loadingItem = {
          id: -1,
          sku: sku ? sku : -1,
          name: '',
        };
        return new MagentoItem(loadingItem as any);
      });
  }
  static getRecommendations() {
    if (WD.store.recommendations.length === 0) {
      const loading: IGetRecommendationsResponse = {
        id: -1 + '',
        description: 'Loading..',
        number_of_product: 0,
        name: 'Loading...',
        image: '',
        banner: '',
        mobile_image: '',
        mobile_banner: '',
        created_at: '',
      };
      return [{...loading}, {...loading}, {...loading}];
    }
    return WD.store.recommendations;
  }

  static async getRecommendationDetail(id: string): Promise<IRecommendation> {
    const detail = WD.store.recommendationDetails.find((r) => r.id + '' === id);
    if (!detail) {
      const r = await WD.store.service.getRecommendation(id);
      await ProductActions.addToAllItems(r.products);
      r.id = id;
      WD.store.recommendationDetails = [...WD.store.recommendationDetails, r];
      return r;
    }
    return detail;
  }

  static isWineInWishList(wine: Wine) {
    if (WD.store.wishList) {
      const item = WD.store.wishList.find((i) => i === wine.getSku());
      if (item) {
        return true;
      }
    }
    return false;
  }
  static isReadyToShowItems() {
    if (WD.store.countries && WD.store.regions && WD.store.grapeVarieties) {
      return true;
    }
    return false;
  }
  private static getProductAttributeList(productAttributes: ProductAttributeMap, idString: string): string[] {
    if (productAttributes) {
      if (idString.indexOf(',') >= 0) {
        const ids = idString.split(',');
        const attributes: string[] = [];
        ids.forEach((id, index) => {
          if (id) {
            const item = productAttributes[id];
            if (item) {
              attributes.push(item.trim());
            } else {
              // console.log('cant find product attribute:', id);
            }
          }
        });
        return attributes;
      } else {
        const item = productAttributes[idString];
        if (item) {
          return [item];
        } else {
          // console.log('cant find product attribute:', idString);
        }
      }
    }
    return ['Loading'];
  }
  private static getProductAttribute(productAttributes: ProductAttributeMap, idString: string) {
    if (productAttributes) {
      if (idString.indexOf(',') >= 0) {
        const ids = idString.split(',');
        let str = '';
        ids.forEach((id, index) => {
          if (id) {
            const item = productAttributes[id];
            if (item) {
              str += (index !== 0 ? ', ' : '') + item;
            } else {
              // console.log('cant find product attribute:', id);
            }
          }
        });
        return str;
      } else {
        const item = productAttributes[idString];
        if (item) {
          return item;
        } else {
          // console.log('cant find product attribute:', idString);
        }
      }
    }
    return 'Loading';
  }
  static getCountry(id: string) {
    return this.getProductAttribute(WD.store.countries, id);
  }
  static getGrapeVariety(id: string, decode = true) {
    return decode
      ? decodeChar(this.getProductAttribute(WD.store.grapeVarieties, id))
      : this.getProductAttribute(WD.store.grapeVarieties, id);
  }
  static getGrapeVarietyList(id: string, decode = true) {
    return decode
      ? decodeChar(this.getProductAttributeList(WD.store.grapeVarieties, id))
      : this.getProductAttributeList(WD.store.grapeVarieties, id);
  }
  static getRegion(id: string) {
    return this.getProductAttribute(WD.store.regions, id);
  }
  static getType(id: string) {
    let name = this.getProductAttribute(WD.store.productTypes, id);
    if (!name) {
      const map = {
        '223': 'Red Wine',
        '224': 'White Wine',
        '229': 'Sweet and Fortified Wine',
        '1015': 'Spirit',
        '1016': 'Rosé Wine',
        '1017': 'Sparkling Wine',
        '1042': 'Organic',
      };
      name = map[id];
    }
    return name;
  }
  static getBottleSize(id: string) {
    const map = {
      '0-375': '<375ml',
      '375': 'Half',
      '375-750': '375ml - 750ml',
      '750': 'Standard',
      '750-1500': '750ml - 1500ml',
      '1500': 'Magnum',
      '1500-99999': '>1500ml',
    };
    const name = map[id];
    return name;
  }
  static getProductAttributeWithName(attributeName: string, id: any) {
    if (attributeName.indexOf('type') >= 0) {
      return this.getType(id);
    }
    if (attributeName.indexOf('region') >= 0) {
      return this.getProductAttribute(WD.store.regions, id);
    }
    if (attributeName.indexOf('grape_varieties') >= 0) {
      return this.getProductAttribute(WD.store.grapeVarieties, id);
    }
    if (attributeName.indexOf('country') >= 0) {
      return this.getProductAttribute(WD.store.countries, id);
    }
    if (attributeName.indexOf('bottle_size_group') >= 0) {
      return this.getBottleSize(id);
    }
    if (attributeName.indexOf('has_sales') >= 0) {
      return id;
    }
    return 'UNKNOWN_ATTRIBUTE';
  }

  static async registerUser(customer: ICreateCustomerResponse) {
    return await WD.store.service.createCustomer(customer);
  }

  static async resetPassword(currentPassword: string, newPassword: string) {
    return await WD.store.service.resetPassword(currentPassword, newPassword);
  }
  static async sendForgotPasswordEmail(email: string) {
    return await WD.store.service.sendForgotPasswordEmail(email);
  }
  static replaceNewLine(str: string): string {
    return str.replace(/<br[.\s]*\/[.\s]*>/g, '\n');
  }
  // Used only for decoding GoogleMap polyline, currently not used
  static decodePolyline(encoded) {
    const points = [];
    let index = 0,
      len = encoded.length;
    let lat = 0,
      lng = 0;
    while (index < len) {
      var b,
        shift = 0,
        result = 0;
      do {
        b = encoded.charAt(index++).charCodeAt(0) - 63; //finds ascii                                                                                    //and substract it by 63
        result |= (b & 0x1f) << shift;
        shift += 5;
      } while (b >= 0x20);

      const dlat = (result & 1) != 0 ? ~(result >> 1) : result >> 1;
      lat += dlat;
      shift = 0;
      result = 0;
      do {
        b = encoded.charAt(index++).charCodeAt(0) - 63;
        result |= (b & 0x1f) << shift;
        shift += 5;
      } while (b >= 0x20);
      const dlng = (result & 1) != 0 ? ~(result >> 1) : result >> 1;
      lng += dlng;

      points.push({latitude: lat / 1e5, longitude: lng / 1e5});
    }
    return points;
  }
  static isWineRequested(sku: string) {
    return WD.store.requestedWineSkus.indexOf(sku) >= 0;
  }
  static getCustomAttribute(obj: {custom_attributes: ICustomAttribute[]}, attribute_code: string): any {
    if (!obj || !obj.custom_attributes) {
      return undefined;
    }
    const found = obj.custom_attributes.find((a) => a.attribute_code === attribute_code);
    if (found) {
      return found.value;
    }
    return undefined;
  }
  static setCustomAttribute(obj: {custom_attributes: ICustomAttribute[]}, attribute_code: string, value) {
    obj = deepClone(obj);
    if (!obj.custom_attributes) {
      obj.custom_attributes = [];
    }
    if (obj) {
      const found = obj.custom_attributes.find((a) => a.attribute_code === attribute_code);
      if (found) {
        found.value = value;
      } else {
        obj.custom_attributes.push({attribute_code, value});
      }
    }
    return obj;
  }
  static isUserInternal(): boolean {
    if (this.store.customer && this.store.customer.email) {
      const email = this.store.customer.email;
      return this.isEmailInternal(email);
    }
    return false;
  }
  static isEmailInternal(email: string) {
    for (const str of INTERNAL_USERS) {
      if (email.indexOf(str) >= 0) {
        return true;
      }
    }
    return false;
  }
  static shouldShowAvailableTomorrow(): boolean {
    return !!this.getAvailableTomorrowDate();
  }
  static getAvailableTomorrowDate(): string {
    let day = undefined;
    const now = toTimeZone(new Date(), 8);
    const dayOfTheWeek = now.getDay();
    const hour = now.getHours();
    const DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000;
    const tomorrow = new Date(now.getTime() + DAY_IN_MILLISECONDS);
    const dayAfterTomorrow = new Date(tomorrow.getTime() + DAY_IN_MILLISECONDS);
    const tomorrowFormated = formatToMagentoDate(tomorrow);
    const afterTomorrowFormated = formatToMagentoDate(dayAfterTomorrow);
    if ((dayOfTheWeek === 4 && hour >= 17) || (dayOfTheWeek === 5 && hour < 17)) {
      day = 'Saturday';
    } else if ((dayOfTheWeek === 5 && hour >= 17) || (dayOfTheWeek === 6 && hour < 17)) {
      day = 'Sunday';
    } else if (PUBLIC_HOLIDAYS.indexOf(afterTomorrowFormated) >= 0 && hour >= 17) {
      day = getDayOfWeek(dayAfterTomorrow);
    } else if (PUBLIC_HOLIDAYS.indexOf(tomorrowFormated) >= 0 && hour < 17) {
      day = getDayOfWeek(tomorrow);
    }
    return day;
  }
  static getCartItemsByDate() {
    const cartInfo = WD.store.cartInfo;
    const dates: {
      date: string;
      show: boolean;
    }[] = [];
    if (cartInfo) {
      cartInfo.items
        .filter((i) => i && i.extension_attributes)
        .map((i) => i.extension_attributes.earliest_delivery_date)
        .forEach((d) => {
          if (!dates.find((dd) => dd.date === d.date)) {
            dates.push(d);
          }
        });
    }
    const cartItems = this.getCartItems();
    const dateItems: {
      date: {
        date: string;
        show: boolean;
      };
      items: {
        item: MagentoItem;
        total: IInCartItemTotal;
        inCartItem: InCartItem;
        otherDetails: InCartItemOtherDetails;
      }[];
    }[] = dates.map((date) => {
      return {
        date: {...date, date: this.formatDate(date.date, {offset: 0})},
        items: cartItems
          .filter(({inCartItem}) => inCartItem.extension_attributes)
          .filter(({inCartItem}) => inCartItem.extension_attributes.earliest_delivery_date.date === date.date),
      };
    });
    const sorted = dateItems.sort((a, b) => {
      if (new Date(a.date.date) > new Date(b.date.date)) {
        return -1;
      }
      return 1;
    });
    return sorted;
  }
  static getCartItems(): ICombinedCartItem[] {
    const cartTotal = WD.store.cartTotal;
    if (WD.store.cartInfo && cartTotal && cartTotal.items && cartTotal.items.length > 0) {
      return cartTotal.items
        .map((total) => {
          const sku = WD.getCartOperator().getSkuByTotalId(total.item_id);
          const inCartItem = WD.store.cartInfo.items.find((i) => i.sku === sku);
          const item = WD.getItem(sku);

          const earliestDeliveryDate = item.isWine()
            ? deepClone(inCartItem.extension_attributes.earliest_delivery_date)
            : undefined;
          if (earliestDeliveryDate) {
            earliestDeliveryDate.date = this.formatDate(earliestDeliveryDate.date);
          }
          const subtotal = Number(total.row_total_incl_tax).toFixed(2);
          let specialPrice = item.getSpecialPrice();
          if (item.getTieredPrices()) {
            item.getTieredPrices().forEach((discount) => {
              if (inCartItem.qty >= discount.qty) {
                specialPrice = discount.value;
              }
            });
          }
          const otherDetails: InCartItemOtherDetails = {
            earliestDeliveryDate,
            subtotal,
            specialPrice,
          };
          return {total, sku, item, inCartItem, otherDetails};
        })
        .filter(({inCartItem}) => inCartItem);
    } else {
      return [];
    }
  }

  static getCustomerAddress(customer: ICustomer, id: number) {
    if (!id) {
      return null;
    }
    const address = {...customer.addresses.find((a) => a.id === id)};
    address.region = '';
    delete address.default_billing;
    delete address.default_shipping;
    delete address.id;
    return address;
  }
  static getCurrentSortingTitle() {
    let title = WD.store.appLocale.customer_web.search.sort_by_new_arrivals;
    const current = WD.store.globalSearchOptions.sort;
    const currentOption = getSortOptionList().find((o) => o.key === current);
    if (currentOption) {
      title = WD.store.appLocale.customer_web.search[currentOption.display];
    }
    return title;
  }
  static getSearchFilters(buckets: IElasticSearchBucket[] = WD.store.globalSearchFilterBuckets): ISearchFilter[] {
    let searchFilters: ISearchFilter[] = [];
    console.log('logging buckets for possible undefined/null', buckets);
    Logger.logInfo('Bucket Logging', 'logging buckets for possible undefined/null', buckets);
    for (const bucket of buckets) {
      const bucketName = bucket.name;
      const items: ISearchFilterItem[] = [];
      for (const filter of bucket.values) {
        if (typeof filter === 'object') {
          let amount = filter.metrics[0];
          if (bucketName === 'bottle_size_group') {
            amount = filter.metrics[2];
          }
          const name = WD.getProductAttributeWithName(bucketName, filter.value);
          if (amount > 0) {
            items.push({
              name,
              amount,
              _id: filter.value,
            });
          }
        }
      }
      const searchOptionKey = bucketName.replace('_id', '') as any;
      const _name = searchOptionKey.replace(/_/g, ' ').toUpperCase();
      const filterNames = {
        TYPE: WD.store.appLocale.customer_web.search.types,
        'GRAPE VARIETIES': WD.store.appLocale.customer_web.search.grape_varieties,
        COUNTRY: WD.store.appLocale.customer_web.search.countries,
        REGION: WD.store.appLocale.customer_web.search.regions,
        'BOTTLE SIZE GROUP': WD.store.appLocale.customer_web.search.bottle_sizes,
      };
      searchFilters.push({
        name: filterNames[_name] || _name,
        items,
        searchOptionKey,
        sortRanking: getSearchFilterRanking(_name),
      });
    }
    searchFilters = searchFilters.filter((f) => f.name !== 'PRICE').sort((a, b) => a.sortRanking - b.sortRanking);
    return searchFilters;
  }

  static getSearchFilterPriceRange(): {min: number; max: number} {
    const {globalSearchFilterBuckets} = WD.store;
    const priceBucket = globalSearchFilterBuckets.find((b) => b.name.indexOf('price') >= 0);
    if (priceBucket) {
      return {min: priceBucket.values[0] as number, max: priceBucket.values[1] as number};
    }
    return {min: 0, max: 0};
  }
  static getGlobalSearchOptionValue(key: string) {
    return WD.store.globalSearchOptions[key];
  }
  // get similar wines by keywords, currently not used
  static async getSimilarWines(keywords: string, {page = 1, pageSize = 24} = {}) {
    let results: string[] = [];
    let iter = 0;
    const maxIter = 5;
    while (results.length <= 0 && iter < maxIter) {
      results = await ProductActions.searchProduct(
        {keywords},
        {
          page,
          pageSize,
          isGlobal: false,
        },
      );
      if (results.length <= 0) {
        if (keywords.indexOf(' ') >= 0) {
          const split = keywords.split(' ');
          keywords = split
            .slice(0, split.length - 1)
            .reduce((p, c) => `${p} ${c}`, '')
            .trim();
        } else {
          keywords = '*';
        }
      }
      iter++;
    }
    return {keywords, skus: results};
  }
  static getOptimizedImageUrl(
    url: string,
    width: number | string,
    height: number | string,
    objectFit: 'cover' = 'cover',
  ) {
    return `${getImageS3Url()}${getUrlDomain(url)}/${height}x${width}/${objectFit}/${encodeURIComponent(
      encodeURIComponent(url),
    )}`;
  }
  static cartContainOutOfStock() {
    const orderedByDate = WD.getCartItemsByDate();
    const notEnoughProduct = [];
    try {
      for (const item of orderedByDate) {
        const wine = item.items.filter((i) => i.total.qty > 0);
        for (const x of wine) {
          const data: Wine = x.item as any;
          Logger.logInfo('CART_OUT_OF_STOCK', 'CHECK', data);
          const qty = data.getStock();
          if (qty <= 0 || !data.isAvailable()) {
            return {
              status: true,
              message:
                'There are products in your cart which are currently unavailable. Please remove them from your cart before proceeding to checkout.',
            };
          }
          if (x.inCartItem.qty > qty) {
            notEnoughProduct.push(x.inCartItem.name);
          }
        }
      }
      if (notEnoughProduct.length > 0) {
        return {
          status: true,
          message: `There is no longer sufficient stock of ${notEnoughProduct.join(
            '; ',
          )}. Please adjust the quantity before checking out.`,
        };
      }
      return {
        status: false,
        message: '',
      };
    } catch (err) {
      Logger.logError('CART_OUT_OF_STOCK', 'ERROR', orderedByDate);
      return {
        status: true,
        message: 'There are something wrong when validating your cart products',
      };
    }
  }
  static canChangeOrderDatetime(deliveryDate: Date) {
    // limit is D-1 at 8 PM
    deliveryDate.setDate(deliveryDate.getDate() - 1);
    const limitDate = new Date(deliveryDate.getFullYear(), deliveryDate.getMonth(), deliveryDate.getDate(), 20);
    console.log(limitDate);
    const currentDate = new Date();
    console.log(currentDate);
    if (currentDate.getTime() > limitDate.getTime()) {
      return false;
    } else {
      return true;
    }
  }
  static showWarningOutOfStockCart(): string {
    const orderedByDate = WD.getCartItemsByDate();
    const warning = 'There are products which is currently unavailable in your cart.';
    const outStockProducts = [];
    for (const item of orderedByDate) {
      const wine = item.items.filter((i) => i.total.qty > 0);
      for (const x of wine) {
        const data: Wine = x.item as any;
        const qty = data.getStock();
        if (qty <= 0 || !data.isAvailable()) {
          outStockProducts.push(x.item.getName());
        }
      }
    }
    return outStockProducts.length > 0 ? warning + outStockProducts.join(' ,') : '';
  }

  static checkInvalidCountry(countryId): boolean {
    return countryId !== 'SG';
  }
}

export type InCartItemOtherDetails = {
  earliestDeliveryDate: {show: boolean; date: string};
  subtotal: string;
  specialPrice: number;
};
